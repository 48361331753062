// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .similar-events-section_MP1WG{padding:0 0;margin-top:16px}html.app__layout--desktop .similar-events-section--cards_yO8KZ{padding:0 0}html.app__layout--desktop .similar-events-section-header_Us_Ls{margin-bottom:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"similar-events-section": `similar-events-section_MP1WG`,
	"similar-events-section--cards": `similar-events-section--cards_yO8KZ`,
	"similar-events-section-header": `similar-events-section-header_Us_Ls`
};
export default ___CSS_LOADER_EXPORT___;
