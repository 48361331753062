export { SportFilterKickoffPeriod } from '@leon-hub/api-sdk/src/sdk/sdk';
export * from './CyberSportDiscipline';
export * from './SportFamily';
export var CustomFilter;
var CustomFilter1;
(CustomFilter1 = CustomFilter || (CustomFilter = {}))["MyFavoriteLeagues"] = "MyFavoriteLeagues", CustomFilter1["AllEvents"] = "AllEvents", CustomFilter1["None"] = "None", CustomFilter1["Stream"] = "Stream", CustomFilter1["Favorites"] = "Favorite", CustomFilter1["ZeroMarginDeprecated"] = "Margin-0", CustomFilter1["ZeroMargin"] = "best-odds", CustomFilter1["Cybersport"] = "Cybersport";
export var FilterIdentificator;
var FilterIdentificator1;
// all favorites page (events and leagues) value same to favorite events
/** @deprecated use ZeroMargin "best-odds" instead */ // is used for navigation
(FilterIdentificator1 = FilterIdentificator || (FilterIdentificator = {}))["SPORT"] = "sport-filter", FilterIdentificator1["CUSTOM"] = "custom-filter";
export var LogoSource;
var LogoSource1;
(LogoSource1 = LogoSource || (LogoSource = {}))["Scores"] = "scores", LogoSource1["Manual"] = "manual";
export var MarketTypeSign;
var MarketTypeSign1;
(MarketTypeSign1 = MarketTypeSign || (MarketTypeSign = {}))["Default"] = "Default", MarketTypeSign1["HomeDrawAway"] = "HomeDrawAway", MarketTypeSign1["HomeAway"] = "HomeAway", MarketTypeSign1["YesNo"] = "YesNo", MarketTypeSign1["UnderOver"] = "UnderOver", MarketTypeSign1["OddEven"] = "OddEven";
export var MatchProgressTimeDirection;
var MatchProgressTimeDirection1;
(MatchProgressTimeDirection1 = MatchProgressTimeDirection || (MatchProgressTimeDirection = {}))["None"] = "None", MatchProgressTimeDirection1["ReversedNone"] = "ReversedNone", MatchProgressTimeDirection1["Direct"] = "Direct", MatchProgressTimeDirection1["Reversed"] = "Reversed";
export var SportEventMatchPhase;
var SportEventMatchPhase1;
// Static, but we need show info about reversed direction
(SportEventMatchPhase1 = SportEventMatchPhase || (SportEventMatchPhase = {}))["PreGame"] = "PRE_GAME", SportEventMatchPhase1["InPlay"] = "IN_PLAY", SportEventMatchPhase1["GameAbandoned"] = "GAME_ABANDONED", SportEventMatchPhase1["BreakInPlay"] = "BREAK_IN_PLAY", SportEventMatchPhase1["Overtime"] = "OVERTIME", SportEventMatchPhase1["PostGame"] = "POST_GAME";
export const SelectionTag = {
    YES: 'YES',
    NO: 'NO',
    UNDER: 'UNDER',
    OVER: 'OVER',
    ODD: 'ODD',
    EVEN: 'EVEN',
    HOME: 'HOME',
    DRAW: 'DRAW',
    AWAY: 'AWAY',
    NEITHER: 'NEITHER',
    BOTH: 'BOTH',
    COMPETITOR: 'COMPETITOR',
    RANGE: 'RANGE',
    SCORE: 'SCORE',
    OTHER: 'OTHER',
    HOMEDRAW: 'HOMEDRAW',
    HOMEAWAY: 'HOMEAWAY',
    DRAWAWAY: 'DRAWAWAY'
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SportEventMarketTypeTag = {
    TOTAL: 'TOTAL',
    HANDICAP: 'HANDICAP',
    REGULAR: 'REGULAR'
};
export var SportEventStatus;
var SportEventStatus1;
// eslint-disable-next-line @typescript-eslint/no-redeclare
(SportEventStatus1 = SportEventStatus || (SportEventStatus = {}))[SportEventStatus1["Open"] = 1] = "Open", SportEventStatus1[SportEventStatus1["Suspended"] = 2] = "Suspended", SportEventStatus1[SportEventStatus1["Closed"] = 3] = "Closed";
export var SportlineType;
var SportlineType1;
(SportlineType1 = SportlineType || (SportlineType = {}))[SportlineType1["Live"] = 1] = "Live", SportlineType1[SportlineType1["Prematch"] = 2] = "Prematch";
export var SportSegmentId;
var SportSegmentId1;
(SportSegmentId1 = SportSegmentId || (SportSegmentId = {}))["Default"] = "default", SportSegmentId1["CyberSport"] = "cyberSport";
export var SportWidgetType;
var SportWidgetType1;
(SportWidgetType1 = SportWidgetType || (SportWidgetType = {}))["SPORTRADAR"] = "SPORTRADAR", SportWidgetType1["LSPORTS"] = "LSPORTS", SportWidgetType1["BET_GENIUS"] = "BETGENIUS", SportWidgetType1["NONE"] = "NONE";
export const SportlineEventPeriodType = {
    Main: 1,
    Additional: 2
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MarketGroupStyleType = {
    DEFAULT: 'DEFAULT',
    PROMO: 'PROMO'
}; // eslint-disable-next-line @typescript-eslint/no-redeclare
